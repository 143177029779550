import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";

import Hero from "../sections/common/Hero";

const FormStyled = styled.form``;

const Contact1 = ({data}) => {
  const document = data.allPrismicContactDetails.nodes[0]

  return (
    <>
      <PageWrapper footerLight>
        <Section>
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <FormStyled
                  name="contact1"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  {/* You still need to add the hidden input with the form name to your JSX form */}
                  <input type="hidden" name="form-name" value="contact1" />

                  <Box mb={5} className='pt-5 pt-md-0'>
                    <Title>How Can We Help You Grow?</Title>
                  </Box>
                  <Box mb={3}>
                    <Input
                      type="text"
                      placeholder="Your name"
                      name="name"
                      required
                    />
                  </Box>
                  <Box mb={3}>
                    <Input
                      type="email"
                      placeholder="Email address"
                      name="email"
                      required
                    />
                  </Box>

                  <Box mb={3}>
                    <Input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      required
                    />
                  </Box>

                  <Box mb={3}>
                    <Input
                      type="text"
                      as="textarea"
                      placeholder="Write your message"
                      rows={4}
                      name="message"
                      required
                    />
                  </Box>

                  <Button width="100%" type="submit" borderRadius={10}>
                    Send Message
                  </Button>
                </FormStyled>
              </Col>
              <Col
                lg={5}
                className="offset-lg-1 order-lg-2 mt-5 mt-lg-0 pt-lg-5"
              >
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Call us
                  </Title>
                  <a href={`tel:${document.data.phone_number.text}`}><Text>{document.data.phone_number.text}</Text></a>
                </Box>
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Email us
                  </Title>
                  <a href={`mailto:${document.data.email_address.text}`}><Text>{document.data.email_address.text}</Text></a>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact1;

export const query = graphql`
query ContactPage {
  allPrismicContactDetails {
    nodes {
      data {
        abn {
          text
        }
        address {
          text
        }
        email_address {
          text
        }
        phone_number {
          text
        }
      }
    }
  }
}
`
